.container {
    background-color: #512781;
}

.res-img {
    width: 80%;
    margin-left: 10%;
    padding-bottom: 6%;
}

h1 {
    color: white;
    text-align: center;
    padding-top: 3%;
    padding-bottom: 3%;
}

.button {
    width: 60%;
    background-color: #5d656f;
}

.button:hover {
    background-color: #222529;

}

a h1 {
    font-size: calc(14pt + 3vh);
}