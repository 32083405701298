.footer {
    width: 100%;
    background-color: #222529;
}

.flex-wrap {
    margin: auto;
}


#socials-container {
    width: 90%;
    display: flex;
    justify-content: space-around;
}

