.home-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 670px;
    padding-top: 50px;
    background-color: #512781;
}

p {
    color: white;
    text-align: center;
    font-size: 22px;
    width: 80%;
    margin-left: 10%;
    padding-top: 5%;
    padding-bottom: 5%;
}

.profile-container {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    min-height: fit-content;
}

.profile-parent {
    display: flex;
    align-items: center;
    color: aliceblue;
}

.profile-details-name {
    font-size: 28px;
    font-family: "Poppins SemiBold";
    margin-bottom: 20px;
}

.profile-details-role {
    display: flex;
    flex-direction: column;
    margin: 14px 0 24px 0;
}

.primary-text {
    color: (--white);
}

.highlighted-text{
    color: rgb(255, 255, 255);
}

.profile-role-tagline {
    font-size: 22px;
    margin: 5px 0 0 0;
    font-family: "Poppins Light";
}

.profile-picture {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1px 0px 0px var(--white);
    border-radius: 50%;
    height: 380px;
    width: 380px;
    margin: 0 0px 0 100px;
}

.profile-picture-background {
    height: 92%;
    width: 92%;
    background-image: url('../../../assets/Home/Me.jpeg');
    background-size: cover;
    border-radius: 50%;
    background-repeat: no-repeat;
    /* background-position: center; */
}

.profile-picture-background:hover {
    transform: scale(1.07);
    transition: 1s ease-out;
}

.colz .colz-icon i{
    color: white;
    padding: 8px;
    /* padding-left: 0px; */
}

.colz .colz-icon i:hover{
    transform: scale(1.5);
}


@media screen and (max-width: 1110px) {
    .profile-picture{
        margin: 0 0px 0 80px
    }
    .profile-details-name {
        font-size: 20px;
    }
    .profile-options .btn{
        width: 120px;
        padding: 11px 0;
        z-index: 700;
    }
}

@media screen and (max-width: 910px) {
    .profile-picture{
        height: 320px;
        width: 320px;
        margin: 0 0 0 4px;
    }
}

@media screen and (max-width: 810px) {
    .profile-picture {
        margin: 0 !important;
    }
    .profile-parent {
        flex-direction: column-reverse;
        margin: 40px 0 0 0;
    }

    .profile-details {
        margin: 25px 0 0 0;
    }
    .profile-options .highlighted-btn {
        margin: 0 !important;
    }
    .profile-options, .profile-details-role {
        text-align: center;
    }
}

@media screen and (max-width: 375px) {
    .profile-picture {
        height: 275px;
        width: 275px;
        margin-top: -90px;
    }
    .profile-details {
        width: 90%;
    }
    p {
        font-size: 20px;
    }
}
