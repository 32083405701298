h1, h2 {
    color: white;
    text-align: center;
}

.port-img {
    width: 100%;
}

a {
    color: white;
}

a:hover {
    color: black;
}

.example {
    border: 3px solid #222529;
    margin-bottom: 50px;
    padding: 25px;
}

.container {
    padding-bottom: 2%;
}

a h2 {
    text-decoration: underline;
}

p {
    font-size: calc(12pt + 1vh);
}

@media screen and (max-width: 800px){
    h1 {
        font-size: calc(16px + 5vh)
    }

    p {
        font-size: 14pt;
    }
}